import React, { useState } from 'react'
import Layout from '../../components/Layout'
import Head from '../../components/Head'

import { portfolioContents } from '../../contents'
import './styles.scss'

const ProjectItem = ({ name, image, tags, links, i }) => {
  const [showLink, setShowLink] = useState(false)
  const linkPosition = showLink ? '0' : '100%'

  const onOptionsClick = () => {
    setShowLink(showLink => !showLink)
  }
  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-12 project-item d-flex- flex-column">
      <div className="main">
        <div className="options">
          <div
            className="options-container"
            onClick={onOptionsClick}
            role="button"
            tabIndex={i}
          >
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
        <div className="inner">
          <div className="project-image">
            <div className="aspect-ratio-img"></div>
            <div className="project-img-inner">
              <div className="project-img-inner-wrap">
                <div className="project-links d-flex justify-content-around align-items-center">
                  {Object.keys(links).map((link, i) => {
                    return (
                      <a
                        key={`${link}-${i}`}
                        href={links[link]}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-primary"
                      >
                        View on {link}
                      </a>
                    )
                  })}
                </div>
                <div
                  className="project-img-bg"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
                <img src={image} className="project-img-main" alt={name} />
              </div>
            </div>
            <div
              className="link-inner"
              style={{ transform: `translateY(${linkPosition})` }}
            >
              <div className="project-links d-flex justify-content-around align-items-center">
                {Object.keys(links).map((link, i) => {
                  return (
                    <a
                      key={`${link}-${i}`}
                      href={links[link]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-sm"
                    >
                      View on {link}
                    </a>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="d-flex flex-column align-items-start">
          <span className="project-name">{name}</span>
          <div className="tags d-flex flex-wrap pr-3 mb-3">
            {tags.map((tag, i) => {
              return (
                <span key={`${tag}-${i}`} className="tag-item">
                  {tag}
                </span>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const ProjectGrid = ({ list }) => {
  return (
    <div className="project-grid-container row col-12  animate fade-in">
      {list.map(({ name, image, tags, links }, i) => {
        return (
          <ProjectItem
            key={`${name} - ${i}`}
            i={i}
            name={name}
            image={image}
            tags={tags}
            links={links}
          />
        )
      })}
    </div>
  )
}

const ProjectPage = () => {
  return (
    <Layout isNavVisible={true}>
      <Head title="My Portfolio" />
      <div id="projectsPage" className="row no-gutters flex-column flex-grow-1">
        <div className="row no-gutters col-12 header animate fade-in">
          <div className="col-12 col-lg-10 col-xl-6">
            <h1>Developer Portfolio</h1>
            <p>
              From simple websites and applications to ReactJS, GatsbyJS and
              Node.JS. Check out my latest web development portfolio projects.
            </p>
          </div>
        </div>
        <ProjectGrid list={portfolioContents.projectList} />
      </div>
    </Layout>
  )
}

export default ProjectPage
